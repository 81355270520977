<div
  class="run-card-inner"
  [ngClass]="{
    'released-accepted-result': selectedAssay?.status === 'RELEASED' && !selectedAssay?.updatedResult?.previousResult,
    'current-result': !muted,
    'has-changes': selectedAssay?.updatedResult?.previousResult === result.resultId,
    CANCELED: selectedAssay?.status === 'CANCELED',
  }"
  data-cy="previous-result">
  <div luxLayout="row" luxLayoutAlign="space-between center">
    @if (result.value.noResult && (!collapsable || expanded)) {
      <span data-cy="run-result-no-result-value" class="run-result">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
          <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#blocked"></use>
        </svg>
      </span>
    } @else if (!isArray(result.presentationValue) && (!collapsable || expanded)) {
      <span data-cy="run-result-value" class="run-result" luxFlex="33%" luxLayout="row"  luxLayoutAlign="start">
        @if (!result?.value?.previousResult && result?.value?.emptyResult !== true) {
          <span>{{ result.presentationValue }}
            <div class="save-badge">
              <svg
                *ngIf="selectedAssay?.updatedResult?.previousResult === result.resultId"
                aria-labelledby="title"
                class="spot-icon results-has-changes-icon"
                data-cy="assay-result-has-changes-icon">
                <title>{{ 'LABELS.HAS_CHANGES' | translate }}</title>
                <use xlink:href="../../assets/icons/spot_icons.svg#save"></use>
              </svg>
            </div>
          </span>
        } @else {
          <!-- empty span for acceptance test -->
          <span fxFlex="100%" data-cy="run-result-empty-result-value"></span>
        }
      </span>
      <span luxFlex="33%" class="run-range">
        <lux-clinical-reference
          luxFill
          [muted]="muted"
          [indicator]="result.relevanceIndicator"
          [clinicalReference]="selectedAssay?.clinicalReference"></lux-clinical-reference>
      </span>
      <span luxFlex="12%" luxLayoutAlign="end">
          @if (!result?.value?.previousResult && !result?.value?.emptyResult) {
          <label class="spot-form__radio" data-cy="previous-result-radio">
            <input
              #radioInput
              type="radio"
              class="spot-form__radio-input"
              name="currentResult"
              (click)="selectCurrentResult()"
              [checked]="(result.resultId === selectedAssay.result.resultId && !selectedAssay?.updatedResult?.previousResult) || result.resultId === selectedAssay?.updatedResult?.previousResult"
              value="" />
            <span class="spot-form__radio-inner">
              <span class="spot-form__radio-visual"></span>
            </span>
          </label>
        }
      </span>
    } @else if (isArray(presentationValue) && (!collapsable || expanded)) {
      <div luxFlex="100%" class="run-result" luxLayoutAlign="start">
        <div luxFlex="90%">
          @for (
            resultValue of presentationValue;
            track resultValue.timestamp
          ) {
            <div luxLayout="column" class="run-result-value" data-cy="run-result-value">
              <span luxFlex="100%">{{ resultValue.display }}</span>
              @if (resultValue?.count && resultValue?.interval) {
                <span
                  class="run-result-range"
                  data-cy="result-value-range"
                  luxFlex="100%"
                  luxLayoutAlign="start center"
                  >{{ resultValue.interval.customerFacingText }}</span
                >
              }
            </div>
            <div class="save-badge">
              <svg
                *ngIf="selectedAssay?.updatedResult?.previousResult === result.resultId"
                aria-labelledby="title"
                class="spot-icon results-has-changes-icon"
                data-cy="assay-result-has-changes-icon">
                <title>{{ 'LABELS.HAS_CHANGES' | translate }}</title>
                <use xlink:href="../../assets/icons/spot_icons.svg#save"></use>
              </svg>
            </div>
          }
        </div>
        <div luxFlex="10%">
          @if (!result?.value?.previousResult && !result?.value?.emptyResult) {
            <label class="spot-form__radio" data-cy="previous-result-radio">
              <input
                #radioInput
                type="radio"
                class="spot-form__radio-input"
                name="currentResult"
                (click)="selectCurrentResult()"
                [checked]="(result.resultId === selectedAssay.result.resultId && !selectedAssay?.updatedResult?.previousResult) || result.resultId === selectedAssay?.updatedResult?.previousResult"
                value="" />
              <span class="spot-form__radio-inner">
                <span class="spot-form__radio-visual"></span>
              </span>
            </label>
          }
        </div>
      </div>
    }
  </div>

  @if (result.transformedValue) {
    <div luxLayout="row" luxLayoutAlign="space-between center">
      <span data-cy="run-result-transformed-value" class="run-result-transformed-value">{{
        result.transformedValue
      }}</span>
    </div>
  }

  <div luxLayout="row" luxLayoutAlign="space-between center">
    <span luxLayout="column">
      <span class="run-result-source" data-cy="result-source">
        <span>{{ getAssayResultEnteredByDisplayName(result) }}</span>
      </span>

      <span data-cy="result-timestamp" *ngIf="lab" class="run-time">{{ result.timestamp | datePipe: lab }}</span>
    </span>
    @if (!collapsable || expanded) {
      <span luxFlexAlign="end" class="error-flags">
        @for (flag of result.flags; track flag.code) {
          <span class="flag" data-cy="assay-details-instrument-flag" title="{{ flag.message }}">{{ flag.code }}</span>
        }
      </span>
    }
    @if (collapsable && result.resultId !== selectedAssay?.updatedResult?.previousResult) {
      <a
        data-cy="result-expand-button"
        href="javaScript:void(0);"
        (keydown.space)="selectCurrentResult()"
        (click)="expanded = !expanded"
        title="{{ 'LABELS.SHOW_ALL' | translate }}"
        class="spot-link expand-btn">
        ...
      </a>
    }
  </div>
</div>
