import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppStateService } from '../../app-state.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { StandardWorkspaceAccession, WorkspaceAccessionService } from './workspace-accession.service';
import { AssayCardComponent } from '../assay/assay-card/assay-card.component';
import { NEVER, of, Subscription, throwError, map } from 'rxjs';
import { AssayService, ConflictError } from '../assay/assay.service';
import { Assay } from '../../interfaces/assay.interface';
import {
  GlobalErrorHandlerService,
  KeyboardAction,
  KeyboardService,
  Lab,
  LabNotesComponent,
  LabNotesService,
  ModalContainerService,
} from '@lims-common-ux/lux';
import { NgForm } from '@angular/forms';
import { AssayDetailsComponent } from '../assay/assay-details/assay-details.component';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { Panel } from '../../panel/panel.interface';
import { ComponentCanDeactivateDirective } from '../../can-deactivate/component-can-deactivate';
import { AssayCardService } from '../assay/assay-card/assay-card.service';
import { WorkspaceQueueService } from '../workspace-queue/workspace-queue.service';
import { LoadedAccessionInfo } from './loaded-accession.class';
import { CanDeactivateService } from '../../can-deactivate/can-deactivate.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  templateUrl: './accession.component.html',
  styleUrls: ['./accession.component.scss'],
})
export class AccessionComponent extends ComponentCanDeactivateDirective implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('assayCard')
  assayCards!: QueryList<AssayCardComponent>;

  @ViewChild('assayDetails', { static: false })
  assayDetails: AssayDetailsComponent;

  @ViewChild('form')
  form: NgForm;

  @ViewChild('labNotes')
  labNotes: LabNotesComponent;

  accession: StandardWorkspaceAccession;
  headerAccession: Accession;
  // tslint:disable-next-line:variable-name
  private _assays: Assay[]; // all assays
  get assays(): Assay[] {
    return this._assays;
  }

  set assays(assays: Assay[]) {
    this._assays = assays;
    this.divideAssays();
  }

  singleValueAssays: Assay[]; // assays that can only have a single value
  multiValueAssays: Assay[]; // assays that can have multiple values to be displayed
  panels: Panel[]; // all panels
  currentAssayCard: AssayCardComponent;
  currentAssaySub: Subscription;
  errorSub: Subscription;
  hasNewPanelComments = false;
  lab: Lab;
  preventSubmit = false;
  private focusFirstAssaySub: Subscription;

  saveButtonEnabled = false;
  acceptButtonEnabled = false;

  assayCardPresentationReady: boolean;

  focusPanelCommentAction: KeyboardAction = {
    name: 'focus-panel-comment',
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);

      if (!this.panels || this.panels?.length < 1 || !this.panels[0].canModify) {
        return;
      }

      this.focusPanelComment();
    },
  };

  focusFirstAssayAction: KeyboardAction = {
    name: 'assay-first-focus',
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      this.focusFirstAssay();
    },
  };

  private focusAssayCommentAction: KeyboardAction = {
    name: 'focus-assay-comment',
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);

      if (this?.assayDetails?.selectedAssay && this.assayDetails.selectedAssay.canModify) {
        this.assayDetails.focusResultComment();
      }
    },
  };

  private acceptAssayAction = {
    name: 'accept-all-assay-results',
    eventMatch: { key: 'F11' },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      if (this.acceptButtonEnabled && !this.labNotes?.hasUnsavedLabNotes) {
        this.saveAssays(true);
      }
    },
  } as KeyboardAction;

  private saveAction = {
    name: 'save-assays',
    eventMatch: {
      key: 's',
      altKey: true,
      matcher: () => !this.modalService.openModal,
    },
    matchCallback: ($evt) => {
      // Wont be present if feature is off
      if (this.labNotes && this.labNotes.visible) {
        $evt.preventDefault();
        this.labNotes.addLabNote();
      } else if (this.saveButtonEnabled && !this.labNotes?.hasUnsavedLabNotes) {
        this.saveAssays();
      }
    },
  } as KeyboardAction;

  private noResultAssayAction = {
    name: 'no-result-assay',
    matchCallback: ($evt) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      if (!this.currentAssayCard.assay.updatedResult.noResult) {
        this.handleNoResult();
      }
    },
  } as KeyboardAction;

  private repeatAssayAction = {
    name: 'repeat-assay',
    eventMatch: { key: 'F5' },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);

      this.handleRepeatAssay();
    },
  } as KeyboardAction;

  nextInQueueAction = {
    name: 'next-in-queue',
    eventMatch: {
      key: 'ArrowRight',
      altKey: true,
      matcher: () => !this.modalService.openModal,
    },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      if (
        !this.appStateService.workspaceQueueEmptyMessageVisible &&
        this.appStateService.queueWorkspace &&
        !this.labNotes?.hasUnsavedLabNotes
      ) {
        this.nextInQueue();
      }
    },
  } as KeyboardAction;

  constructor(
    private activated: ActivatedRoute,
    private workspaceAccessionService: WorkspaceAccessionService,
    private workspaceQueueService: WorkspaceQueueService,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private assayCardService: AssayCardService,
    private assayService: AssayService,
    private canDeactivateService: CanDeactivateService,
    public appStateService: AppStateService,
    private keyboardService: KeyboardService,
    private modalService: ModalContainerService,
    private labNotesService: LabNotesService
  ) {
    super();
  }

  canDeactivate(): boolean {
    // False pops a message here
    return !this.saveButtonEnabled;
  }

  ngOnInit(): void {
    this.activated.data.pipe(map((data) => data.accessionInfo as LoadedAccessionInfo)).subscribe((info) => {
      if (info.assays?.length) {
        this.setAccessionInfo(info);
      }
    });

    this.errorSub = this.globalErrorHandlerService.errors.subscribe((err) => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.preventSubmit = true;
      }
    });

    this.keyboardService.addActions([
      this.focusFirstAssayAction,
      this.focusPanelCommentAction,
      this.saveAction,
      this.noResultAssayAction,
      this.focusAssayCommentAction,
      this.acceptAssayAction,
      this.repeatAssayAction,
      this.nextInQueueAction,
    ]);

    this.currentAssaySub = this.appStateService.currentAssaySub.subscribe((updatedAssayCard) => {
      this.currentAssayCard = updatedAssayCard;
    });

    this.focusFirstAssaySub = this.appStateService.focusFirstAssayEvent.subscribe(() => {
      this.focusFirstAssay();
    });
  }

  ngAfterViewInit() {
    if (!this.appStateService.workspaceQueueEmptyMessageVisible) {
      this.form.statusChanges.subscribe((newVal) => {
        this.setCanSave();
        this.setCanAccept();
      });
    }
  }

  ngOnDestroy() {
    this.keyboardService.removeAction(this.saveAction);
    this.errorSub.unsubscribe();
    this.assayCardService.reset();

    if (this.currentAssaySub) {
      this.currentAssaySub.unsubscribe();
    }
    if (this.focusFirstAssaySub) {
      this.focusFirstAssaySub.unsubscribe();
    }
  }

  private setAccessionInfo(loaded: LoadedAccessionInfo) {
    this.assayCardPresentationReady = false;
    this.accession = loaded.accession;
    this.headerAccession = loaded.resultAccession;
    this.assays = loaded.assays;
    this.assayCardService.setInitialAssaysAndVersions(this.assays);
    this.panels = loaded.panels;
    this.lab = this.appStateService.lab;
    this.resetFormAndFocus();
  }

  private resetFormAndFocus() {
    setTimeout(() => {
      this.hasNewPanelComments = false;
      this.appStateService.loading = false;
      this.focusFirstAssay();
      this.setPristine();
    }, 0);
  }

  divideAssays() {
    if (this.assays) {
      this.multiValueAssays = [];
      this.singleValueAssays = [];

      this.assays.forEach((assay) => {
        if (
          assay?.resultDefinition?.valueType === 'SEMI_QUANTITATIVE_COMBO' ||
          assay?.resultDefinition?.valueType === 'DEFINED_MULTI_TEXT' ||
          assay?.resultDefinition?.valueType === 'DEFINED_TEXT_NUMERIC_COMBO'
        ) {
          this.multiValueAssays.push(assay);
        } else {
          this.singleValueAssays.push(assay);
        }
      });
    }
  }

  getAssayNeedsAttention(assay: Assay) {
    let assayNeedsAttention;
    if (assay.status === 'OPENED') {
      const assayCard = this.assayCards.find((card) => card.assay.testCode === assay.testCode);
      if (
        assayCard.presentationClass?.indexOf('ATTENTION') > -1 ||
        assayCard.presentationClass?.indexOf('ALERT') > -1
      ) {
        assayNeedsAttention = assay;
      }
    }
    return assayNeedsAttention;
  }

  focusPanelComment() {
    this.appStateService.currentAssay = null;

    requestAnimationFrame(() => {
      this.assayDetails.panelsView?.panelComment?.focusSearchInput();
    });
  }

  focusFirstAssay() {
    if (this.assays?.length) {
      const selectFirstAssay = this.singleValueAssays[0] || this.multiValueAssays[0];

      this.setCurrentAssay(selectFirstAssay);

      // To prevent visual flash of intermediate presentation states, delay card visibility until styles are ready
      this.showAssayCards();
    }
  }

  showAssayCards() {
    this.assayCardPresentationReady = true;
    this.currentAssayCard?.selectResultInput();
  }

  setCurrentAssay(assay: Assay) {
    this.appStateService.currentAssay = this.assayCards.find((card) => card.assay.testCode === assay.testCode);
  }

  // Does not include defined text inputs?
  // This method navigates to the next assay card following valid result input, no result, or repeat request
  handleValueChange() {
    let next;
    this.assayCards.forEach((card, index) => {
      if (this.currentAssayCard && this.currentAssayCard.assay.testCode === card.assay.testCode) {
        next = index + 1;
      }

      if (next === index) {
        setTimeout(() => {
          if (card.resultInput) {
            card.resultInput.focusInput();
          } else {
            card.displayValueLink?.nativeElement?.focus();
          }
        }, 0);
      }
    });
  }

  saveAssays(technicallyAccept: boolean = false) {
    this.assayCardPresentationReady = false;
    this.appStateService.loading = true;
    this.assayService
      .saveAssays(this.accession, this.assays, technicallyAccept, this.panels)
      .pipe(
        catchError((e) => {
          this.form.form.setErrors({ saveError: true });
          if (e instanceof ConflictError) {
            return NEVER;
          } else {
            return throwError(() => e);
          }
        }),
        switchMap((assays) => {
          const updatedAssayList = [...this.assays];
          assays.forEach((updated) => {
            const replaceIndex = updatedAssayList.findIndex((original) => original.testCode === updated.testCode);
            updatedAssayList.splice(replaceIndex, 1, updated);
          });
          this.assays = updatedAssayList;

          // Set up assays to be flagged with saved changes in the current session
          // so we can focus the first assay that requires user attention
          this.assayCardService.setCurrentAssaysAndVersions(this.assays);

          return this.workspaceAccessionService.loadPanels(this.accession);
        }),
        tap((panels) => {
          this.panels = panels;
        })
      )
      .subscribe(() => {
        this.resetFormAndFocus();
      });
  }

  handleRepeatAssay() {
    const currentAssay = this.findCurrentAssay();
    if (currentAssay.canModify) {
      this.assayService.repeat(currentAssay, () => {
        this.handleNonValueResultUpdates();
      });
    }
  }

  handleNoResult() {
    // Assays are @Input to AssayCardComponent, so updating the assay via this.assays will automatically
    // trigger change detection.
    const currentAssay = this.findCurrentAssay();
    if (currentAssay.canModify) {
      this.assayService.noResult(currentAssay, () => {
        this.handleNonValueResultUpdates();
      });
    }
  }

  handleNonValueResultUpdates() {
    // don't loose the card since we move to a timeout, and something could loose focus on the card.
    this.currentAssayCard.markAsTouchedDirty();
    this.form.form?.markAsDirty();
    this.handleValueChange();
    this.setCanAccept();
    this.setCanSave();
  }

  // Catches emit from assay comment add in details view
  handleAssayUpdated() {
    this.currentAssayCard.markAsTouchedDirty();
    this.currentAssayCard.setCardPresentation();
    this.setCanSave();
    this.setCanAccept();
  }

  // Mark the form dirty here to allow save or accept with only a panel comment update
  handlePanelUpdated($event) {
    this.hasNewPanelComments = true;
    this.form.form?.markAsDirty();
    this.setCanSave();
    this.setCanAccept();
  }

  setPristine() {
    if (this.form?.form) {
      this.form?.form.markAsPristine(); // makes sure form is set to initial/untouched state after assays load
    }

    this.assayCards.forEach((card) => {
      card.markAsPristineUntouched();
      card.setCardPresentation();
    });

    this.setCanSave();
    this.setCanAccept();
    this.focusFirstAssay();
  }

  // Details View (not assay card) escape key handling
  handleDetailsEscape($event?) {
    if (this.currentAssayCard) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      this.currentAssayCard.selectResultInput();
    }
  }

  // The save button is enabled when:
  // The accession has assays that have valid result changes
  // The accession has assays assays or panels with comment updates
  setCanSave() {
    setTimeout(() => {
      if (!this.appStateService.workspaceQueueEmptyMessageVisible) {
        let hasSavableChanges;

        // form?. check because it may not be rendered if we are loading an accession through the queue.
        hasSavableChanges = (this.hasNewPanelComments || this.form?.dirty) && this.form.valid;

        this.saveButtonEnabled = hasSavableChanges;

        // Observable required here for canDeactivate route guard
        this.appStateService.hasSavableChanges = of(this.saveButtonEnabled);
      }
    }, 0);
  }

  // The accept button is enabled when:
  // The accession has assays that are 'saved' but not yet accepted
  // The accession has assays with valid changes
  setCanAccept() {
    setTimeout(() => {
      if (!this.appStateService.workspaceQueueEmptyMessageVisible) {
        const hasAcceptableAssays = this.assays.filter((assay) => {
          return (
            (assay.status === 'RESULT_RECEIVED' || assay.status === 'TECHNICIAN_REVIEW') &&
            !assay.result?.value?.emptyResult
          );
        });

        // form?. check because it may not be rendered if we are loading an accession through the queue.
        const hasAcceptableChanges = (hasAcceptableAssays?.length > 0 || this.form?.dirty) && this.form.valid;

        this.acceptButtonEnabled = hasAcceptableChanges;
      }
    }, 0);
  }

  nextInQueue() {
    const goToNext = this.canDeactivateService.unsavedChangesAlert(this.saveButtonEnabled);

    if (goToNext) {
      // we explicitly clear accession information
      // since we dont change the url
      this.appStateService.accessionHeader = null;
      this.appStateService.accession = null;
      this.appStateService.currentAssay = null;
      this.appStateService.loading = true;
      this.assays = [];
      this.panels = [];
      this.accession = null;

      if (this.labNotesService.labNotesOpen) {
        this.labNotes?.closeLabNotesModal();
      }

      this.workspaceQueueService
        .advanceQueue(this.appStateService.workspaceQueueNextUrl, this.appStateService.currentWorkspace)
        .subscribe((loadedAccession) => {
          if (loadedAccession.accession) {
            this.setAccessionInfo(loadedAccession);
          }

          this.appStateService.loading = false;
        });
    }
  }

  private findCurrentAssay(): Assay {
    return this.assays.find((assay) => assay.testCode === this.currentAssayCard.assay.testCode);
  }
}
