<div
  #semiQuantitativeResultInputWrapper
  (focusout)="handleFocusOut()"
  [class.spot-form--error]="control.errors"
  class="semi-quantitative-result-wrapper">
  <input
    #input
    (blur)="handleBlur($event)"
    (input)="handleInput($event)"
    (keydown.backspace)="handleBackspace()"
    (keydown.enter)="handleEnter($event)"
    [class.active]="!hidden"
    [attr.aria-disabled]="disabled"
    [readonly]="disabled"
    [name]="name"
    [placeholder]="placeholder"
    [tabindex]="tabindex"
    [value]="val"
    class="spot-form__input"
    data-cy="semi-quantitative-result-input"
    spellcheck="false"
    autocomplete="off"
    type="text" />
  <span
    data-cy="semi-quantitative-result-input-error"
    *ngIf="control.errors?.inputError"
    class="spot-form__field-error"
    id="error-must-be-number"
    role="alert">
    <span class="spot-form__field-error-text" data-cy="result-value-error"
      >{{ 'ERRORS_AND_FEEDBACK.MUST_BE_NUM' | translate }}
    </span>
  </span>
  <span
      data-cy="semi-quantitative-result-input-error"
      *ngIf="control.errors?.outOfRange"
      class="spot-form__field-error"
      id="error-out-of-range"
      role="alert">
      <span class="spot-form__field-error-text" data-cy="result-value-error"
        >{{ 'ERRORS_AND_FEEDBACK.OUT_OF_RANGE' | translate }}
      </span>
    </span>
  <div *ngIf="showError" class="spot-form__field-error" role="alert" data-cy="semi-quantitative-result-input-error">
    <span class="spot-form__field-error-text" data-cy="result-value-error"
      >{{ 'ERRORS_AND_FEEDBACK.NO_MATCHES' | translate }}
    </span>
  </div>
</div>
