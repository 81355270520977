<form data-cy="assays-form" #form="ngForm" luxLayout="column" luxFill *ngIf="accession !== null">
  <div
    [ngClass]="assayCardPresentationReady ? 'presentationReady' : 'presentationReady'"
    id="accession-wrapper"
    luxLayout="row wrap"
    luxFill>
    <app-assay-details
      #assayDetails
      [assayCardHasChanges]="currentAssayCard?.presentationClass?.indexOf('HAS_CHANGES') > -1"
      [lab]="lab"
      [panels]="panels"
      [headerAccession]="headerAccession"
      [workspaceAccession]="accession"
      (keydown.escape)="handleDetailsEscape($event)"
      (requestRepeat)="handleRepeatAssay()"
      (markAsNoResult)="handleNoResult()"
      (selectedAssayUpdated)="handleAssayUpdated()"
      (previousResultSelected)="handleAssayUpdated()"
      (panelUpdated)="handlePanelUpdated($event)"
      (escapeResultEntry)="handleDetailsEscape($event)"></app-assay-details>
    <fieldset luxFlex luxLayout="column" data-cy="assays-fieldset" [disabled]="appStateService.loading">
      <div luxLayout="row wrap" *ngIf="assays?.length">
        <ng-container *ngFor="let assay of singleValueAssays; let first = first; let last = last; let index = index">
          <app-assay-card
            #assayCard
            (click)="setCurrentAssay(assay)"
            (valueChange)="handleValueChange()"
            [lab]="lab"
            [assay]="assay"
            [firstCard]="first"
            [lastCard]="last && !multiValueAssays?.length">
          </app-assay-card>
        </ng-container>
      </div>
      <div luxLayout="row wrap" *ngIf="multiValueAssays && multiValueAssays.length" class="multi-value-assays">
        <ng-container *ngFor="let assay of multiValueAssays; let first = first; let last = last; let index = index">
          <app-assay-card
            #assayCard
            (click)="setCurrentAssay(assay)"
            (valueChange)="handleValueChange()"
            [lab]="lab"
            [assay]="assay"
            [firstCard]="first && !singleValueAssays?.length"
            [lastCard]="last">
          </app-assay-card>
        </ng-container>
      </div>

      <!-- z-index required to get contents above the main section of the body when required, 9998 is the
       loading overlay, so we want it below that, but above everything else, except the flyout. -->
      <div luxLayout="row" style="position: fixed; bottom: 13px; right: 20px; z-index: 8000">
        <div luxLayout="row" luxLayoutGap="13px">
          <lux-lab-notes
            #labNotes
            *luxIfFeature="'LAB_NOTES'"
            [getLabNotesLink]="appStateService.getLabNotesLink"
            [addLabNoteLink]="appStateService.addLabNoteLink"
            [accessionId]="accession.accessionId"
            dragBoundary="#accession-wrapper"
            [lab]="lab"
            [context]="'STANDARD_WORKSPACE'"
            [labNotesPosition]="{ x: -600, y: -600 }"></lux-lab-notes>
          <button
            type="button"
            data-cy="accept-button"
            class="spot-button spot-button--primary"
            title="{{ 'LABELS.ACCEPT_ALL' | translate }}"
            (click)="saveAssays(true)"
            [disabled]="(labNotes?.hasUnsavedLabNotes$ | async) || !acceptButtonEnabled">
            <!--            async pipe should be on the left side of Logical OR expression because it's evaluated left to right
                if it will be on the right, then async pipe will be returning null on acceptButtonEnabled change until hasUnsavedLabNotes$ will trigger new value
                 this happens because labNotes is not available when template rendering and on async pipe first run it returns null.
                 Another solution is to make hasUnsavedLabNotes$ a BehaviorSubject that will have a value regardless of labNotes availability-->
            {{ 'LABELS.ACCEPT_ALL' | translate }}
          </button>
          <button
            type="button"
            data-cy="save-button"
            class="spot-button spot-button--secondary"
            title="{{ 'LABELS.SAVE' | translate }}"
            [disabled]="(labNotes?.hasUnsavedLabNotes$ | async) || !saveButtonEnabled"
            (click)="saveAssays()">
            {{ 'LABELS.SAVE' | translate }}
          </button>
          <button
            type="button"
            data-cy="workspace-queue-next"
            title="{{ 'LABELS.SKIP' | translate }}"
            *ngIf="appStateService.queueWorkspace"
            [disabled]="labNotes?.hasUnsavedLabNotes$ | async"
            (click)="nextInQueue()"
            class="spot-button next-link spot-button--secondary spot-button--icon-only spot-button--with-icon">
            <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
              <title>{{ 'LABELS.SKIP' | translate }}</title>
              <use xlink:href="../../assets/icons/spot_icons.svg#back"></use>
            </svg>
          </button>
        </div>
      </div>
    </fieldset>
  </div>
</form>
