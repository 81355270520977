import { Component, Input } from '@angular/core';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { FeaturesService, Sample, Workspace } from '@lims-common-ux/lux';
import { StandardWorkspaceAccession } from '../accession/workspace-accession.service';
import { AppStateService } from '../../app-state.service';

@Component({
  selector: 'app-workspace-navigation',
  templateUrl: './workspace-navigation.component.html',
  styleUrls: ['./workspace-navigation.component.scss'],
})
export class WorkspaceNavigationComponent {
  @Input()
  headerAccession: Accession;
  @Input()
  workspaceAccession: StandardWorkspaceAccession;
  @Input()
  currentWorkspace: Workspace;
  selectedSample$ = this.appStateService.currentSample$;
  chemAsStandardWorkspaceFeatureIsOn = false;

  constructor(public appStateService: AppStateService, private featuresService: FeaturesService) {
    this.chemAsStandardWorkspaceFeatureIsOn = this.featuresService.hasFeature('CHEM_AS_STANDARD_WORKSPACE');
  }

  handleSampleChange(sample: Sample) {
    this.appStateService.currentSample = sample;
  }
}
