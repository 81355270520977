import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppStateService } from '../../app-state.service';
import { WorkspaceQueueService } from './workspace-queue.service';
import { LoadedAccessionInfo } from '../accession/loaded-accession.class';

@Injectable({ providedIn: 'root' })
export class QueueAccessionResolver {
  constructor(private appStateService: AppStateService, private workQueuesService: WorkspaceQueueService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LoadedAccessionInfo> {
    const workspace = route.parent.data.workspace;
    // match the current workspace to an item in the workspace queue list
    const workQueue = this.appStateService.workQueues.find(
      (wq) => wq.workspaceId === this.appStateService.currentWorkspace.id
    );

    if (workQueue) {
      return this.workQueuesService.advanceQueue(workQueue._links.first, workspace);
    } else {
      return of(null);
    }
  }
}
