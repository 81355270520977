import { Lab, LabsService, Link } from '@lims-common-ux/lux';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

interface LabList {
  _embedded: {
    labs: Lab[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class StandardWorkspaceLabService {
  constructor(private http: HttpClient, private labsService: LabsService) {}

  // Standard Workspace is returning the correct structure, using _embedded. Other apps should get updated to match, and once
  // they do the common lib can change to match.
  loadLabs(labLink: Link): Observable<Lab[]> {
    return this.http.get<LabList>(labLink.href).pipe(map((list) => list._embedded.labs));
  }

  /**
   * Just wraps the service defined in lux so other items can depend on just this service.
   */
  updateLab(lab: Lab) {
    this.labsService.updateLab(lab);
  }
}
