import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { DefinedTextValue, ShortCodeMapping } from '@lims-common-ux/lux';
import { Subscription } from 'rxjs';
import { AssayService } from '../../assay.service';

@Component({
  selector: 'app-defined-multi-text',
  templateUrl: './defined-multi-text.component.html',
  styleUrls: ['../semi-quantitative-result-combo/semi-quantitative-result-combo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DefinedMultiTextComponent),
      multi: true,
    },
  ],
})
export class DefinedMultiTextComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input()
  resultOptions: DefinedTextValue[] = [];

  @Input()
  placeholder = '';

  @Input()
  noResult = false;
  @Output()
  noResultChange = new EventEmitter<boolean>();

  @Input()
  hidden = true;

  @Input()
  tabindex = 1;

  // tslint:disable-next-line:no-input-rename
  @Input('value')
  val: string[] = null;

  @Input()
  initialValue: string[] = null;

  @Input()
  name;

  @Input()
  shortCodes: ShortCodeMapping;

  @Input()
  showPrefix = false;

  @Output()
  lostFocus = new EventEmitter<true>();

  @Input()
  disabled: boolean;

  @ViewChild('resultInput', { static: false })
  input: ElementRef;

  @ViewChild('wrapper', { static: false })
  cmpWrapper: ElementRef;

  @ViewChildren('resultsListItem')
  resultsListItems!: QueryList<ElementRef>;

  @ViewChildren('deleteIcons')
  deleteIcons!: QueryList<ElementRef>;

  filteredResultOptions: DefinedTextValue[] = [];

  showError = false;
  showRemovedSavedError = false;
  control: AbstractControl;
  statusSub: Subscription;
  initialFocus = false;

  @Input()
  editMode = false;

  @Input()
  repeatRequested: boolean;

  isNoneSeenResult(resultCode: string) {
    const selectedResult = this.resultOptions.find((resultOption) => resultOption.code === resultCode);
    return selectedResult && selectedResult.noneSeen;
  }

  get value() {
    return this.val;
  }

  set value(val: string[]) {
    this.val = val;
    if (this.val?.length > 0) {
      this.noResult = false;
      this.noResultChange.emit(this.noResult);
    }
    this.updateDisplay();
    this.onChange(val);
  }

  constructor(private assayService: AssayService, private injector: Injector) {}

  ngOnInit() {
    const model = this.injector.get(NgControl);

    this.control = model.control;

    this.statusSub = this.control.statusChanges.subscribe((status: string) => {
      setTimeout(() => {
        this.setErrorState(this.control.errors);
      }, 0);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes?.noResult?.currentValue || changes?.repeatRequested?.currentValue) && this.input) {
      setTimeout(() => {
        this.resetErrorState();
        this.input.nativeElement.value = '';
      }, 0);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onChange: any = () => {
    // empty on purpose
  };

  onTouched: any = () => {
    // empty on purpose
  };

  writeValue(value: string[]) {
    this.val = value;
  }

  optionChanged(value: string | string[], remove: boolean = false) {
    // handles no result rewrite
    if (!value && this.value) {
      this.value = null;
      return;
    }

    if (!value) {
      return;
    }

    if (this.value === null) {
      this.value = [];
    }

    if (!Array.isArray(value)) {
      value = [value] as string[];
    } else {
      this.value = value;

      return;
    }

    for (const newVal of value) {
      if (!remove) {
        // Is the newly selected value noneSeen?
        const isNoneSeen = this.isNoneSeenResult(newVal);

        if (newVal !== null && newVal && !isNoneSeen && (!this.value.length || this.value.indexOf(newVal) === -1)) {
          // Scrub existing results for any noneSeen values.
          const filteredVal = this.value.filter((existingValue: string) => {
            return !this.isNoneSeenResult(existingValue);
          });

          filteredVal.unshift(newVal);
          this.value = filteredVal;
        } else if (isNoneSeen) {
          // The newly selected result is noneSeen, so the only allowed input value is noneSeen.
          this.value = [newVal];
        }
      } else if (remove && this.value.length) {
        const filteredVals = this.value.filter((existingValue: string) => {
          return existingValue !== newVal;
        });

        if (!filteredVals.length) {
          this.value = null;
        } else {
          this.value = filteredVals;
        }
      }
    }

    if (!this.value?.length && !this.control.errors) {
      this.control.markAsPristine();
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  close($event = null) {
    if (!this.hidden && $event) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
    }
    this.hidden = true;
  }

  open() {
    this.hidden = false;
  }

  isClosed() {
    return this.hidden;
  }

  focusInput() {
    this.input.nativeElement.focus();
  }

  verifyCompleteEntry() {
    if (this.input.nativeElement.value) {
      this.input.nativeElement.click();

      requestAnimationFrame(() => {
        this.initialFocus = false;
        this.close();
        this.assignError({ removedSavedValues: true });
      });
    } else if (!this.input.nativeElement.value) {
      this.resetErrorState();
    }
  }

  handleFocusOut($event) {
    setTimeout(() => {
      if (document.activeElement === document.body || !this.cmpWrapper.nativeElement.contains(document.activeElement)) {
        this.initialFocus = false;
        this.close();
        this.lostFocus.emit(true);
      }
    }, 0);
  }

  // alt + e or double clicking puts this component into edit mode
  // which allows us to remove Result values
  toggleEditMode($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    if (this.disabled) {
      return;
    }
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.focusInput();
    }
    return false;
  }

  handleInput() {
    this.onTouched();
    const shortCode = this.input.nativeElement.value.trim().toUpperCase();
    if (shortCode === '') {
      this.filteredResultOptions = this.resultOptions;
    } else {
      this.filteredResultOptions = this.filterResultOptions(shortCode);
    }

    if (this.filteredResultOptions.length) {
      this.open();
      this.assignError({ inputError: false });
    } else {
      if (this.input.nativeElement.value) {
        this.assignError({ inputError: true });
      } else {
        this.assignError({ inputError: false });
      }
    }
  }

  private filterResultOptions(shortCode: string): DefinedTextValue[] {
    const resultOptionsWithoutShortCodeMatches = [];
    const shortCodeMatches = this.resultOptions.filter((definedText: DefinedTextValue) => {
      if (definedText.code === this.shortCodes?.[shortCode]) {
        return true;
      }
      resultOptionsWithoutShortCodeMatches.push(definedText);
      return false;
    });
    const textMatches = resultOptionsWithoutShortCodeMatches
      .filter((definedText: DefinedTextValue) => {
        let definedTextDisplayCode = definedText.display;
        const definedTextMap = [definedTextDisplayCode];
        while (definedTextDisplayCode.length > 0) {
          // umlaut letters Ä, ä, Ö, ö, Ü, ü, ß
          const wordDelimiterPosition = definedTextDisplayCode.search(
            /[^a-zA-Z0-9\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]+/
          );
          if (wordDelimiterPosition === -1) {
            break;
          }
          definedTextDisplayCode = definedTextDisplayCode.substring(wordDelimiterPosition);
          definedTextMap.push(definedTextDisplayCode);
          definedTextDisplayCode = definedTextDisplayCode.substring(1);
          definedTextMap.push(definedTextDisplayCode);
        }

        return definedTextMap.some((definedWord) => definedWord.toUpperCase().startsWith(shortCode));
      })
      .sort((definedTextA: DefinedTextValue, definedTextB: DefinedTextValue) => {
        if (definedTextA.code > definedTextB.code) {
          return 1;
        } else {
          return -1;
        }
      });

    return [...shortCodeMatches, ...textMatches];
  }

  assignError(error) {
    let currentErrors = this.getErrors();

    if (!currentErrors) {
      currentErrors = {
        inputError: false,
        removedSavedValues: false,
      };
    }

    const newErrorState = Object.assign(currentErrors, error);

    if (newErrorState?.inputError === false && newErrorState?.removedSavedValues === false) {
      this.control.setErrors(null);
    } else {
      this.control.setErrors(newErrorState);
    }
  }

  getErrors() {
    return this.control?.errors;
  }

  removeResultValue(item: string, index: number) {
    if (this.editMode) {
      let nextEleToFocus: ElementRef;

      if (this.deleteIcons.length && index === this.deleteIcons.length - 1) {
        index -= 1;
      }

      this.optionChanged(item, true);

      setTimeout(() => {
        if (!this.value?.length && this.initialValue?.length && !this.noResult) {
          this.assignError({ removedSavedValues: true });
        }
      });

      // we want focus to be on next Result Value, otherwise set focus to the input when removing
      setTimeout(() => {
        if (!this.value?.length) {
          nextEleToFocus = this.input;
        } else {
          this.deleteIcons.forEach((eleRef, i) => {
            if (i === index) {
              nextEleToFocus = eleRef;
            }
          });
        }

        nextEleToFocus.nativeElement.focus();

        const err: ValidationErrors = new Validators();
        this.setErrorState(err);
      }, 0);
    }
  }

  handleEnter($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.disabled) {
      return false;
    }

    if (this.isClosed() && !this.input.nativeElement.value) {
      this.filteredResultOptions = this.resultOptions;
      this.open();
    } else if (!this.isClosed()) {
      if (this.filteredResultOptions.length) {
        this.selectOption(this.filteredResultOptions[0], $event);
      }
      this.close();
    } else if (this.isClosed() && this.input.nativeElement.value && $event?.target?.value && this.control.errors) {
      setTimeout(() => {
        this.resetErrorState();

        setTimeout(() => {
          this.handleInput();
        }, 0);
      }, 0);
    }

    return false;
  }

  handleFocus($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (!this.initialFocus) {
      this.initialFocus = true;
    }

    if (
      !this.value?.length &&
      !this.input.nativeElement.value &&
      this.initialFocus &&
      !this.control.errors &&
      !this.disabled
    ) {
      this.filteredResultOptions = this.resultOptions;
      this.open();
    }
  }

  handleArrowDown($event) {
    if (!this.isClosed()) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      this.filteredResultOptions.push(this.filteredResultOptions.splice(0, 1)[0]);

      return false;
    }
  }

  handleArrowUp($event) {
    if (!this.isClosed()) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      const lastItem: DefinedTextValue = this.filteredResultOptions.pop();

      this.filteredResultOptions.unshift(lastItem);

      return false;
    }
  }

  getShortCodeByValue(val: string) {
    let prop;
    let match = '';

    for (prop in this.shortCodes) {
      if (val === this.shortCodes[prop]) {
        match = prop;

        break;
      }
    }

    return match;
  }

  selectOption(definedValue: DefinedTextValue, $event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    if (this.disabled) {
      return;
    }
    this.editMode = false;
    this.optionChanged(definedValue.code);
    this.close();
    this.updateDisplay();
    this.focusInput();
  }

  setErrorState(err: ValidationErrors) {
    setTimeout(() => {
      if (err && err.inputError) {
        this.showError = true;
      } else {
        this.showError = false;
      }

      if (err && err.removedSavedValues) {
        this.showRemovedSavedError = true;
      } else if ((this.value && this.value.length) || this.noResult) {
        this.showRemovedSavedError = false;
      }
    }, 0);
  }

  resetErrorState() {
    this.showError = false;
    this.showRemovedSavedError = false;
    this.control.setErrors(null);
    this.assignError(null);
    this.setErrorState(this.control.errors);
  }

  getObservationDisplayTextByValue(value: string): string {
    return this.assayService.getObservationDisplayTextByValue(this.resultOptions, value);
  }

  private updateDisplay() {
    this.input.nativeElement.value = '';
    this.control.setErrors(null);
  }
}
